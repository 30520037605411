import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { appName, baseUrl, apiUrl } from "@/config/config";
import Image from 'next/image';
import Link from 'next/link';
import { Button } from 'antd';
import { useRouter } from 'next/router';
import { FcLike } from 'react-icons/fc';
import { useQuery } from 'react-query';
import Queries from '@/constants/queries';
import PublicService from '@/services/api/public.service';
import parse from 'html-react-parser';

import SlideAfterMotion from '@/components/common/animation/SlideAfterMotion';
import { motion, useInView, useAnimation } from 'framer-motion';
const Hero = () => {
	const { t } = useTranslation();
	const router = useRouter();

	const { data: homePageData } = useQuery([Queries.cmsHomePage], PublicService.getHomePageData);

	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });
	const mainControls = useAnimation();

	useEffect(() => {
		if (isInView) {
			mainControls.start('visible');
		}
	}, [isInView]);

	const container = {
		hidden: { opacity: 1, scale: 0 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				delayChildren: 0.2,
				staggerChildren: 0.2,
			},
		},
	};

	const item = {
		hidden: { y: 50, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	};

	return (
    <>
      <div className="hero_section_wrapper">
        <div className="bubble"></div>
        <div className="container">
          <motion.div
            ref={ref}
            variants={container}
            initial="hidden"
            animate={mainControls}
            className="hero-section-info"
          >
            <motion.div variants={item} className="hero_info_area">
              <h1>
                Inventory Management Success with{" "}
                <b className="color_title">Fast Inventory</b>
              </h1>

              <SlideAfterMotion>
                <p>{homePageData && parse(homePageData.hero_info)}</p>
              </SlideAfterMotion>

              <SlideAfterMotion>
                <div className="down-link">
                  <button
                    className="landing_primary_button large_button"
                    onClick={(e) => {
                      e.preventDefault();
                      router.push("/login");
                    }}
                  >
                    {homePageData && homePageData.hero_button}
                  </button>
                </div>
              </SlideAfterMotion>
            </motion.div>

            <motion.div variants={item} className="hero_image_wrapper">
              {/* <Image src={baseUrl + '/images/bg/hero-img.png'} alt="Fast Inventory" fill sizes="100vw" /> */}
              <Image
                src={apiUrl + homePageData.hero_image}
                alt="Fast Inventory"
                fill
                sizes="100vw"
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};
export default Hero;
