import { apiUrl } from "../../config/config";
import { api, authApi } from "../interceptor/auth.interceptor";
import { apiEndpoint } from "../apiEndpoint";

class PublicService {
  static async getHomePageData() {
    return await api
      .get(apiUrl + apiEndpoint.publicRoutes.homepage)
      .then((response) => {
        return response?.data;
      });
  }
}

export default PublicService;
